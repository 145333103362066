import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import head from 'lodash/head'
import get from 'lodash/get'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Layout from '../components/Layout'

import './faq.scss'

const FAQPage = ({ data: { allContentfulFaqItem: { faqItems } }, location }) => {
    return (
        <Layout location={location}>
            <SEO
                title='FAQ'
                description='Die häufigsten Fragen und Antworten rund um die Bestellung eines
                    handgezeichneten Tierportraits von TIERGETREU.'
            />
            <div className='faq-page'>
                <Jumbotron className='bg-white'>
                    <h1 className='text-tint'>FAQs</h1>
                    <p></p>
                </Jumbotron>
                <Container>
                    <Accordion defaultActiveKey={head(faqItems).faqItem.contentfulid || 1}>
                        {
                            faqItems.map(({ faqItem }) => (
                                <Card key={faqItem.contentfulid}>
                                    <Accordion.Toggle 
                                    as={Card.Header} 
                                    eventKey={faqItem.contentfulid} 
                                    dangerouslySetInnerHTML={{ __html: faqItem.question.childMarkdownRemark.html }}
                                    />
                                    <Accordion.Collapse eventKey={faqItem.contentfulid}>
                                        <Card.Body
                                            dangerouslySetInnerHTML={{ __html: get(faqItem.answer, 'childMarkdownRemark.html') }}
                                        />
                                    </Accordion.Collapse>
                                </Card>
                            ))
                        }
                    </Accordion>
                </Container>
            </div>
        </Layout>
    )
}

export default FAQPage
export const pageQuery = graphql`
  query FAQPageQuery {
    allContentfulFaqItem(
        sort: { fields: [contentfulid], order: ASC }
    ) {
        faqItems: edges {
            faqItem: node {
                contentfulid
                question {
                    childMarkdownRemark {
                        html
                    }
                }
                answer {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
  }
`
